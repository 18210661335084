import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import { getUser } from "./user";

export const getClientList = createAsyncThunk(
  "client/Listing",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/client`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const getpdfListing = createAsyncThunk(
  "client/pdfListing",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `broker/client/shared/pdf`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const getpdfClientListing = createAsyncThunk(
  "client/pdfListing",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `client/document`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getpdfagentListing = createAsyncThunk(
  "client/pdfListing",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `agent/client/shared/pdf`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const getClientListing = createAsyncThunk(
  "client/Listing",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        list: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getAgentList = createAsyncThunk(
  "agent/Listing",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        records: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getPlanList = createAsyncThunk(
  "plans/Listing",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/resource/payment/plans`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        plans: res,
        total: res?.headers?.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const choosePlan = createAsyncThunk(
  "plans/new",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/resource/payment/plans?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: " Plan Choosen Successfully",
        })
      );
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleAgent = createAsyncThunk(
  "agent/SingleList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent/${payload.id}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        record: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleClientList = createAsyncThunk(
  "client/singlePost",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/client/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleClientListing = createAsyncThunk(
  "client/singlePost",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createClient = createAsyncThunk(
  "client/new",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Client Added Successfully",
        })
      );
      dispatch(getClientListing());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createClients = createAsyncThunk(
  "client/new",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/client/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Client Added Successfully",
        })
      );
      dispatch(getClientList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createAgent = createAsyncThunk(
  "agent/new",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Agent Added Successfully",
        })
      );
      dispatch(getAgentList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const pdfmanipluate = createAsyncThunk(
  "pdf/manipluate",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/client/account/upload_pdf/?${buildQueryString(payload)}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Pdf manipluated Successfully",
        })
      );
      // alert(res.document_url);
      window.open(res.document_url, "_blank")

      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const updateProfileDetails = createAsyncThunk(
  "user/updateProfile",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
        const formDataObj = {};
        formData.forEach((value, key) => {
            formDataObj[key] = value;
        });
      dispatch(startLoading());
      let url = `/resource/users/me`;
      let res = await API.put(config.barong)(url, formData, {headers: {"Content-Type": "multipart/form-data",},});
      dispatch(successToastSelector({message: "Profile Updated Successfully"}));
      dispatch(getUser());
      dispatch(stopLoading());
      return res.data;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateClient = createAsyncThunk(
  "client/update",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/agent/client/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.put(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Client Updated Successfully",
        })
      );
      dispatch(getClientListing());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const updateAgent = createAsyncThunk(
  "client/update",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/agent/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.put(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Agent Updated Successfully",
        })
      );
      dispatch(getAgentList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const updateBrokerClient = createAsyncThunk(
  "client/update",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/broker/client/${payload.id}?${buildQueryString(payload)}`;
      let res = await API.put(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Client Updated Successfully",
        })
      );
      dispatch(getAgentList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const sendPdfClient = createAsyncThunk(
  "sendPdf/client",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `broker/client/${payload.id}/share_pdf/?${buildQueryString(
        payload
      )}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Pdf Manipulated Successfully",
        })
      );
      dispatch(getAgentList());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const sendagentPdfClient = createAsyncThunk(
  "sendPdf/client",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `agent/client/${payload.id}/share_pdf/?${buildQueryString(
        payload
      )}`;
      let res = await API.post(config.barong)(url);
      dispatch(
        successToastSelector({
          message: "Pdf Manipulated Successfully",
        })
      );
      dispatch(getClientListing());
      dispatch(stopLoading());
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
