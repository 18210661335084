import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import IdleTimer from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { rootName } from "./utils/constant";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import GoAlert from "./config/goAlert";
import {
  commonRoutesData,
  privateRoutesData,
  publicRoutesData,
  freeRoutesData,
} from "./routes/routingData";
import {
  Mainlayout,
  PageNotFound,
  Verifiaction,
  VerificationCode,
  Landing,
  Dashboard,
} from "./routes/lazyRoute";
import { getUser } from "./redux/services";
import { isAuthenticated } from "./redux/selector";
import { fetchLogout } from "./redux/services/logout";
import { primaryRoutes, publicRouteObj } from "./staticObjects";
import { useLocation } from "react-router-dom";
import { Loader } from "./Components/loader";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAuthenticate = useSelector((state) => isAuthenticated(state));
  const errorMessage = useSelector((state) => state.alert.message);
  const { data, userIsLoading } = useSelector((state) => state.user);
  const isloading = useSelector((state) => state.loader.loading);
  if (errorMessage[0] == "authz.csrf_token_in_valid") {
    localStorage.clear();
    window.location.reload();
  }

  useEffect(() => {
    dispatch(getUser({ isNull: true }));
  }, []);

  message.config({
    maxCount: 1,
    duration: 5,
  });

  const handleOnIdle = async () => {
    let res = await dispatch(
      fetchLogout({
        uid: data?.uid,
      })
    );
    if (res.payload) {
      navigate(`${rootName}${publicRouteObj.login}`);
    }
    window.alert("Session expired, Please login again.");
  };

  // If level 1 than not need to show inner page
  const { verificationCode, verification } = primaryRoutes;
  let route = [`/${verificationCode}`, `/${verification}`].includes(pathname);
  if (data?.level === 1 && !route) {
    navigate(`${rootName}${verification}`);
    return;
  }

  //fixed login screen showing some time
  if (userIsLoading === null) {
    return <Loader loading={true} />;
  }

  return (
    <>
      <Loader loading={isloading} />
      <Suspense fallback={<Loader loading={true} />}>
        <Routes>
          <Route element={<PublicRoute isAuthenticated={isAuthenticate} />}>
            {publicRoutesData?.map((item, idx) => {
              return (
                <Route
                  key={idx}
                  index
                  path={`${rootName}${item.path}`}
                  element={item.component}
                />
              );
            })}
            {!isAuthenticate && (
              <Route path={`${rootName}`} index element={<Landing />} />
            )}
          </Route>

          <Route element={<PrivateRoute isAuthenticated={isAuthenticate} />}>
            <Route path={`${rootName}`} element={<Mainlayout />}>

              {

              data.active_plan || data.role === 'member' ?
              privateRoutesData?.map((item, idx) => {
                return (
                  <Route
                    index
                    key={idx}
                    path={`${rootName}${item.path}`}
                    element={item.component}
                  />
                );
              })
              :
              freeRoutesData?.map((item, idx) => {
                return (
                  <Route
                    index
                    key={idx}
                    path={`${rootName}${item.path}`}
                    element={item.component}
                  />
                );
              })}
              <Route path="/" element={<Navigate to={`${rootName}`} />} />
            </Route>

            {/* For verify account */}

            <Route path={""} element={<Verifiaction />} />
            <Route
              path={primaryRoutes.verification}
              element={<Verifiaction />}
            />
            <Route
              path={primaryRoutes.verificationCode}
              element={<VerificationCode />}
            />
          </Route>
          <Route path="*" element={<PageNotFound />} />
          {commonRoutesData?.map((item, idx) => {
            return (
              <Route
                key={idx}
                path={`${rootName}${item.path}`}
                element={item.component}
              />
            );
          })}
        </Routes>
      </Suspense>





      {/* For Alert */}
      <GoAlert />

      {isAuthenticate && (
        <IdleTimer
          ref={() => { }}
          timeout={24 * 60 * 60 * 1000} // 24 hours
          onActive={() => { }}
          onIdle={handleOnIdle}
          onAction={() => { }}
          debounce={250}
        />
      )}
    </>
  );
}
export default withTranslation()(App);
