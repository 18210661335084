import {
  Dashboard,
  Login,
  ChangePassword,
  ResetPassword,
  Earn,
  CreateAcount,
  VerifyEmail,
  Identity,
  Portfolio,
  VerifyIdentity,
  EmailConfirmation,
  Landing,
  LogoutCompo,
  Client,
  PricingAgent,
  Faq,
  Services,
  TermsConditions,
  PrivacyPolicy,
  ContactSales,
  Help,
  MainDashboard,
  PDFManiplulation,
} from "./lazyRoute";
import {
  sidebarRouteObj,
  publicRouteObj,
  primaryRoutes,
} from "../staticObjects/routing";

// Public routing array

const {
  login,
  changePassword,
  resetPassword,
  createAccount,
  emailVerify,
  emailConfirmation,
  identity,
  verifyIdentity
} = publicRouteObj;

const {
  authLanding,
  logout,
  pdf,
} = primaryRoutes;

export const commonRoutesData = [
];

export const publicRoutesData = [
  {
    path: primaryRoutes.landing,
    component: <Landing />,
  },
  {
    path: login,
    component: <Login />,
  },

  {
    path: `${changePassword}/:id`,
    component: <ChangePassword />,
  },
  {
    path: resetPassword,
    component: <ResetPassword />,
  },
  {
    path: createAccount,
    component: <CreateAcount />,
  },

  {
    path: emailVerify,
    component: <VerifyEmail />,
  },
  {
    path: `${emailConfirmation}/:id`,
    component: <EmailConfirmation />,
  },

  {
    path: identity,
    component: <Identity />,
  },
  {
    path: verifyIdentity,
    component: <VerifyIdentity />,
  },
  {
    path: primaryRoutes.pricing_agent,
    component: <PricingAgent />,
  },
  {
    path: primaryRoutes.services,
    component: <Services />,
  }, {
    path: primaryRoutes.faq,
    component: <Faq />,
  },
  {
    path: primaryRoutes.terms_conditions,
    component: <TermsConditions />,
  },
  {
    path: primaryRoutes.privacy_policy,
    component: <PrivacyPolicy />,
  },
  {
    path: primaryRoutes.contact_sales,
    component: <ContactSales />,
  },
  {
    path: primaryRoutes.help,
    component: <Help />,
  },
];

// Private routing array

const {
  home,
  port,
  earn,
  client,
  maindashboard,
} = sidebarRouteObj;


export const privateRoutesData = [
  {
    path: ``,
    component: <Dashboard />,
  },
  {
    path: logout,
    component: <LogoutCompo />,
  },
  {
    path: authLanding,
    component: <Landing />,
  },
  {
    path: `${home}`,
    component: <Dashboard />,
  },
  {
    path: `${port}`,
    component: <Portfolio />,
  },
  {
    path: `${earn}`,
    component: <Earn />,
  },
  {
    path: `${client}`,
    component: <Client />,
  },
  {
    path: `${maindashboard}`,
    component: <MainDashboard />,
  },
  {
    path: `${pdf}/:id`,
    component: <PDFManiplulation />,
  },

];


export const freeRoutesData = [
  {
    path: ``,
    component: <Dashboard />,
  },
  {
    path: logout,
    component: <LogoutCompo />,
  },
  {
    path: `${earn}`,
    component: <Earn />,
  },
]