import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { getUser } from "./user";

export const getIdentity = createAsyncThunk(
  "login/getIdentity",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getOtpService = createAsyncThunk(
  "login/getOtpService",
  async ({ otpClicked, ...rest }, { dispatch }) => {
    try {
      let url = `identity/sessions/resent_otp`;
      await API.post(config.barong)(url, rest);
      dispatch(
        successToastSelector({
          message: `OTP ${otpClicked ? "resent" : "sent"}  successfully`,
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

// export const postAgreement = createAsyncThunk(
//   "login/postAgreement",
//   async (payload, { dispatch }) => {
//     try {
//       let url = `resource/users/accept_contract`;
//       await API.post(config.barong)(url);
//       return { status: true };
//     } catch (e) {
//       dispatch(errorToastSelector(e));
//       return false;
//     }
//   }
// );
export const createIdentitySession = createAsyncThunk(
  "login/createIdentitySession",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity/sessions`;
      let res = await API.post(config.barong)(url, payload);
      const {
        session_type,
        rememberMe,
        password,
        email,
        country_code,
        area_code,
        phoneNum,
      } = payload;

      if (rememberMe) {
        // localStorage.setItem("pass", password);
        localStorage.setItem("remember", rememberMe);
        if (session_type === "email") {
          localStorage.setItem("email", email);
        }
        if (session_type === "phone") {
          localStorage.setItem("code", country_code);
          localStorage.setItem("area", area_code);
          localStorage.setItem("phone", phoneNum);
        }
      }
      localStorage.setItem("csrfToken", res.csrf_token);
      dispatch(successToastSelector({ message: "Login Successfully" }));
      dispatch(stopLoading());
      dispatch(getUser());
      return { status: true, data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
