import { createSlice } from "@reduxjs/toolkit";
import { getClientList, getSingleClientList,getpdfListing } from "../../services";

const clientListSlice = createSlice({
    name: "client",
    initialState: {
        error: false,
        loading: false,
        list: [],
        singlePost: {},
        total: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getClientList.pending, (state) => {
                state.error = false;
                state.list = [];
            })
            .addCase(getClientList.fulfilled, (state, action) => {
                state.list = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getClientList.rejected, (state) => {
                state.list = [];
                state.error = true;
            })
            .addCase(getSingleClientList.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(getSingleClientList.fulfilled, (state, action) => {
                state.singlePost = action?.payload;
                state.loading = false;
                state.error = false;
            })
            .addCase(getSingleClientList.rejected, (state) => {
                state.singlePost = {};
                state.error = true;
                state.loading = false;
            })
            .addCase(getpdfListing.pending, (state) => {
                state.error = false;
                state.document = [];
            })
            .addCase(getpdfListing.fulfilled, (state, action) => {
                state.document = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getpdfListing.rejected, (state) => {
                state.document = [];
                state.error = true;
            });

    },
});

export const { } = clientListSlice.actions;
export default clientListSlice.reducer;
